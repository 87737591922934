import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  constructor(private window: Window) {}

  async sha256(str: string) {
    const buf = await this.window.crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(str)
    );
    return Array.prototype.map
      .call(new Uint8Array(buf), (c: number) =>
        ('00' + c.toString(16)).slice(-2)
      )
      .join('');
  }

  generateRandomPassword = async () => {
    const iv = this.window.crypto.getRandomValues(new Uint8Array(16));
    return Array.from(iv)
      .map((b) => ('00' + b.toString(16)).slice(-2))
      .join('');
  };

  encryptValue = async (
    contentToEnc: string,
    password: string
  ): Promise<string> => {
    const data = new TextEncoder().encode(contentToEnc);

    const iv = this.window.crypto.getRandomValues(new Uint8Array(16));
    const algorithm = { name: 'AES-CTR', counter: iv, length: 128 };

    const paddedPassword = (
      await this.sha256([...password].reverse().join(''))
    ).substring(0, 32);

    const key = await this.window.crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(paddedPassword),
      algorithm,
      false,
      ['encrypt']
    ); // generate key from pw

    const ctBuffer = await this.window.crypto.subtle.encrypt(
      algorithm,
      key,
      data
    );

    const ctArray = Array.from(new Uint8Array(ctBuffer)); // ciphertext as byte array
    const ctStr = ctArray.map((byte) => String.fromCharCode(byte)).join(''); // ciphertext as string
    const ctBase64 = this.window.btoa(ctStr); // encode ciphertext as base64

    const ivHex = Array.from(iv)
      .map((b) => ('00' + b.toString(16)).slice(-2))
      .join(''); // iv as hex string

    return ivHex + ctBase64;
  };

  decryptValue = async (
    contentToDec: string,
    password: string
  ): Promise<string> => {
    const paddedPassword = (
      await this.sha256([...password].reverse().join(''))
    ).substring(0, 32); // hash the password

    const iv = (
      contentToDec.slice(0, 32).match(/.{2}/g) as RegExpMatchArray
    ).map((byte) => parseInt(byte, 16)); // get iv from contentToDec

    const alg = { name: 'AES-CTR', counter: new Uint8Array(iv), length: 128 }; // specify algorithm to use

    const key = await this.window.crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(paddedPassword),
      alg,
      false,
      ['decrypt']
    ); // use pw to generate key

    const ctStr = this.window.atob(contentToDec.slice(32)); // decode base64 contentToDec
    const ctUint8 = new Uint8Array(
      (ctStr.match(/[\s\S]/g) as RegExpMatchArray).map((ch) => ch.charCodeAt(0))
    ); // contentToDec as Uint8Array

    const plainBuffer = await this.window.crypto.subtle.decrypt(
      alg,
      key,
      ctUint8
    ); // decrypt contentToDec using key
    const plaintext = new TextDecoder().decode(plainBuffer); // decode password from UTF-8

    return plaintext;
  };
}
