import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { exhaustMap, first, map, of, take } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const authInitCanMatch: CanMatchFn = () => {
  const platformId = inject(PLATFORM_ID);
  const authService = inject(AuthService);
  const router = inject(Router);
  if (isPlatformBrowser(platformId)) {
    return authService.status$.pipe(
      take(1),
      exhaustMap((status) => {
        if (status === 'initial') {
          const { access_token } =
            router.getCurrentNavigation()?.initialUrl.queryParams ?? {};
          return authService.init(access_token).pipe(map(() => true));
        }
        return of(true);
      })
    );
  }
  return true;
};

export const loginRequiredCanMatch: CanMatchFn = (_, segments) => {
  const auth = inject(AuthService);
  return auth.status$.pipe(
    first((status) => status === 'active'),
    exhaustMap(() => {
      if (!auth.loggedIn) {
        return auth.login(
          segments.reduce((acc, segment) => `${acc}/${segment}`, '')
        );
      }
      return of(true);
    })
  );
};
