import { inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { filter, map } from 'rxjs';
import { NavigationActions } from './custom-router.actions';

export const saveNavigationEnd = createEffect(
  (router = inject(Router)) => {
    return router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(({ urlAfterRedirects, url }) =>
        NavigationActions.navigationEnd({ url: urlAfterRedirects || url })
      )
    );
  },
  { functional: true }
);
