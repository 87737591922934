import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { isPlatformServer, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InMemoryCache } from '@apollo/client/core';
import { authInterceptor } from '@fagor-marketplace/hmi-elite-web/data-access-auth';
import { ENVIRONMENT, Environment } from '@fagor/shared/util-environment';
import possibleTypes from '@fagor/shared/util-strapi';
import { CustomRouterModule } from '@fagor/shared/utils/router';
import {
  ENCRYPTED_STORAGE,
  StorageService,
} from '@fagor/utils/encrypted-storage';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import localeEs from '@angular/common/locales/es';
import { ResolveUrlPipe } from '@fagor-marketplace/shared/utils/commons';

registerLocaleData(localeEs, 'es');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApolloModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    CustomRouterModule,
    AppRoutingModule,
  ],
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    CookieService,
    {
      provide: APP_ID,
      useValue: 'hmi-elite-web',
    },
    {
      provide: Window,
      useFactory: () => window,
    },
    {
      provide: ENVIRONMENT,
      useFactory: (window: Window) => new Environment(environment, window),
      deps: [Window],
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink, environment: Environment) => {
        return {
          cache: new InMemoryCache({
            possibleTypes: possibleTypes.possibleTypes,
          }),
          link: httpLink.create({
            uri: `${environment.cmsEndpoint.replace(/\/$/, '')}/graphql`,
          }),
        };
      },
      deps: [HttpLink, ENVIRONMENT],
    },
    ResolveUrlPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: (storage: StorageService, platformId: object) => () =>
        isPlatformServer(platformId)
          ? Promise.resolve()
          : storage.initStorage(),
      deps: [StorageService, PLATFORM_ID],
      multi: true,
    },
    {
      provide: ENCRYPTED_STORAGE,
      useFactory: (platformId: object) => {
        if (isPlatformServer(platformId)) return { storage: {} };
        return { storage: localStorage };
      },
      deps: [PLATFORM_ID],
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        scrollStrategy: new NoopScrollStrategy(),
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
