import { AppEnvironment } from './environment.model';

export class Environment {
  private readonly _cmsEndpoint: string;
  private readonly _window: Window;

  constructor(env: AppEnvironment, window: Window) {
    switch (env.type) {
      case 'DomainBased': {
        const domain = env.domain.replace(/\/$/, '');
        this._cmsEndpoint = `https://cms.${domain}`;
        break;
      }
      case 'HostBased': {
        const { hostname, protocol } = window.location;
        const [, domain] = hostname.split(/^[^.]+?\./);
        this._cmsEndpoint = `${protocol}//cms.${domain}`;
        break;
      }
      case 'Full': {
        const { cmsEndpoint } = env;
        this._cmsEndpoint = cmsEndpoint;
        break;
      }
      default: {
        throw 'Invalid environment';
      }
    }
    this._window = window;
  }

  get cmsEndpoint() {
    return this._cmsEndpoint;
  }

  get cmsApiEndpoint() {
    return `${this._cmsEndpoint}/api`;
  }

  get window() {
    return this._window;
  }
}
