import { createFeature, createReducer, on } from '@ngrx/store';
import { NavigationActions } from './custom-router.actions';

interface State {
  url: string;
  previousUrl: string | null;
}

const initialState: State = {
  url: '',
  previousUrl: null,
};
export const customRouterFeature = createFeature({
  name: 'customRouter',
  reducer: createReducer(
    initialState,
    on(
      NavigationActions.navigationEnd,
      (state, { url }): State => ({ url, previousUrl: state.url })
    )
  ),
});
