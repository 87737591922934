import { inject, Pipe, PipeTransform } from '@angular/core';
import { ENVIRONMENT } from '@fagor/shared/util-environment';

@Pipe({
  name: 'resolveUrl',
  standalone: true,
})
export class ResolveUrlPipe implements PipeTransform {
  private env = inject(ENVIRONMENT);

  transform(value: string): string {
    return value.match(/^https?:\/\//)
      ? value
      : `${this.env.cmsEndpoint}${value}`;
  }
}
