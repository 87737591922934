import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENVIRONMENT } from '@fagor/shared/util-environment';
import { catchError, exhaustMap, throwError } from 'rxjs';
import { AuthService } from './auth.service';

export const authInterceptor: HttpInterceptorFn = (req, handler) => {
  const env = inject(ENVIRONMENT);
  if (!req.url.startsWith(env.cmsApiEndpoint)) {
    return handler(req);
  }
  const authService = inject(AuthService);
  const clonedReq = req.clone({ withCredentials: true });
  return handler(clonedReq).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return authService
          .logout()
          .pipe(
            exhaustMap(() => authService.login(env.window.location.pathname))
          );
      }
      console.error(error);
      return throwError(() => error);
    })
  );
};
