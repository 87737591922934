import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@fagor/shared/util-environment';
import { UsersPermissionsLoginPayload } from '@fagor/shared/util-strapi';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  NEVER,
  Observable,
  catchError,
  finalize,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { LoggedUser, ServiceStatus, User } from '../models';

const USER_COOKIE_KEY = 'fmp_user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private cookieService = inject(CookieService);
  private env = inject(ENVIRONMENT);
  private http = inject(HttpClient);
  private _user: LoggedUser;
  private _menuColor: 'black' | 'white' = 'black';
  private _menuColor$= new BehaviorSubject<'black' | 'white'>('black');
  private _user$ = new BehaviorSubject<LoggedUser>(null);
  private _status$ = new BehaviorSubject<ServiceStatus>('initial');

  get loggedIn(): boolean {
    return Boolean(this._user);
  }

  get user(): LoggedUser {
    return this._user;
  }

  get menuColor(): 'black' | 'white' {
    return this._menuColor;
  }

  set user(user: LoggedUser) {
    this._user = user;
    this._user$.next(this._user);
  }

  set menuColor(menuColor: 'black' | 'white') {
    this._menuColor = menuColor;
    this._menuColor$.next(this._menuColor);
  }

  get user$(): Observable<LoggedUser> {
    return this._user$.pipe(shareReplay(1));
  }

  get menuColor$(): Observable<'black' | 'white'> {
    return this._menuColor$.pipe(shareReplay(1));
  }

  get status$(): Observable<ServiceStatus> {
    return this._status$.asObservable().pipe(shareReplay(1));
  }

  init(accessToken?: string) {
    this._status$.next('starting');
    let action$: Observable<unknown>;
    if (accessToken) {
      action$ = this.callback(accessToken);
    } else if (!this.cookieService.get(USER_COOKIE_KEY)) {
      action$ = of(true);
    } else {
      action$ = this.me();
    }

    return action$.pipe(
      catchError((error) => {
        console.error(`Error auth init.\n`, error);
        return of(true);
      }),
      finalize(() => {
        this._status$.next('active');
      })
    );
  }

  login(callbackUrl: string): Observable<never> {
    this.env.window.location.href = `${this.env.cmsApiEndpoint}/connect/fagorsso?callback=${this.env.window.location.origin}${callbackUrl}`;
    return NEVER;
  }

  changeMenuColor(color:'black'|'white'){
    return this.menuColor = color
  }

  callback(accessToken: string): Observable<User> {
    const params = new HttpParams({
      fromObject: {
        access_token: accessToken,
      },
    });
    return this.http
      .get<UsersPermissionsLoginPayload>(
        `${this.env.cmsApiEndpoint}/auth/fagorsso/callback`,
        {
          params,
          withCredentials: true,
        }
      )
      .pipe(switchMap(() => this.me()));
  }

  logout(): Observable<void> {
    return this.http
      .post<void>(`${this.env.cmsApiEndpoint}/auth/logout`, null)
      .pipe(tap(() => (this.user = undefined)));
  }

  checkUserExist(email: string): Observable<User | null> {
    const params = new HttpParams({
      fromObject: {
        email: email.trim().toLowerCase(),
      },
    });
    return this.http
      .get<User>(`${this.env.cmsApiEndpoint}/user/email`, { params })
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            return of(null);
          }
          throw error;
        })
      );
  }

  private me(): Observable<User> {
    const params = new HttpParams({
      fromObject: {
        'populate[0]': 'group_roles',
        'populate[1]': 'group_roles.group',
        'populate[2]': 'group_roles.group.product_roles',
        'populate[3]': 'group_roles.group.product_roles.product',
        'populate[4]': 'product_roles',
        'populate[5]': 'product_roles.product',
      },
    });
    return this.http
      .get<User>(`${this.env.cmsApiEndpoint}/users/me`, {
        params,
      })
      .pipe(map((user) => (this.user = user)));
  }
}
