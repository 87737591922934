import { Inject, Injectable } from '@angular/core';
import { ObservablifyService } from '@fagor/utils/commons';
import { EncryptionService } from './encryption.service';
import { EncryptedStorageConfig } from './models';
import { ENCRYPTED_STORAGE } from './storage.token';

@Injectable({
  providedIn: 'root',
})
export class StorageService extends ObservablifyService<StorageService> {
  private PASSWD = '';
  private _defaultStoreName: string;
  private _storage: EncryptedStorageConfig['storage'];
  constructor(
    @Inject(ENCRYPTED_STORAGE) storageConfig: EncryptedStorageConfig,
    private encryption: EncryptionService
  ) {
    if (!storageConfig.storage) throw new Error(`Storage not provided`);
    super('setItem', 'getItem', 'removeItem', 'clearAll', 'initStorage');
    this._defaultStoreName = storageConfig.storeName ?? 'default';
    this._storage = storageConfig.storage;
  }

  async initStorage(): Promise<void> {
    const { key } = await this._buildKey('_____MASTERKEY', 'default');
    let pass = await this._storage.getItem(key);

    if (pass == null) {
      const _p = await this.encryption.generateRandomPassword();
      pass = await this.encryption.sha256(_p);
      await this._storage.setItem(key, pass);
    }
    this.PASSWD = pass;
  }

  async setItem<T>(
    _key: string,
    _value: T,
    storeName = this._defaultStoreName
  ): Promise<T> {
    const encryptedValue = await this.encryption.encryptValue(
      JSON.stringify(_value),
      this.PASSWD
    );
    const { key } = await this._buildKey(_key, storeName);
    await this._storage.setItem(key, encryptedValue);
    return _value;
  }

  async getItem<T>(
    _key: string,
    storeName = this._defaultStoreName
  ): Promise<T | null> {
    const { key, clearKey } = await this._buildKey(_key, storeName);
    await this._fixKey(key, clearKey);

    const plainValue = await this._storage.getItem(key);
    if (plainValue == null || plainValue === '') {
      return null;
    }
    try {
      const decryptedValue = await this.encryption.decryptValue(plainValue, this.PASSWD);
      return JSON.parse(decryptedValue);
    } catch (e) {
      console.warn(`Error decrypting value. Using retrieved plain value.`);
      return JSON.parse(plainValue);
    }
  }

  async removeItem(
    _key: string,
    storeName = this._defaultStoreName
  ): Promise<void> {
    const { key, clearKey } = await this._buildKey(_key, storeName);
    await this._fixKey(key, clearKey);
    await this._storage.removeItem(key);
  }

  async clearAll(): Promise<void> {
    await this._storage.clear();
  }

  private async _fixKey(key: string, clearKey: string) {
    const fromClearValue = await this._storage.getItem(clearKey);
    const fromCryptedValue = await this._storage.getItem(key);

    if (fromClearValue == null) {
      return;
    }

    if (fromClearValue && fromCryptedValue === null) {
      const value = await this.encryption.encryptValue(fromClearValue, this.PASSWD);
      await this._storage.setItem(key, value);
      await this._storage.removeItem(clearKey);
    }
  }

  private async _buildKey(
    _key: string,
    _store: string
  ): Promise<{ clearKey: string; key: string }> {
    const clearKey = `${_store}!${_key}`;
    const key = await this.encryption.sha256(`__${clearKey}**`);

    return { clearKey, key };
  }
}
