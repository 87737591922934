import { defer, Observable } from 'rxjs';

type ServiceObservableWrapper<Service> = {
  [propOrMethod in keyof Service]: Service[propOrMethod] extends (
    ...args: infer Args
  ) => Promise<infer ReturnedType>
  ? <ReturnType = ReturnedType>(...args: Args) => Observable<ReturnType>
  : never;
};

export abstract class ObservablifyService<Service> {
  observables: ServiceObservableWrapper<Service>;
  private _validatedKeys = new Map<unknown, boolean>();

  constructor(...parseableMethodKeys: (keyof Service)[]) {
    const _isValidMethod = (propKey: string, item: unknown): boolean => {
      if (this._validatedKeys.has(item) === false) {
        this._validatedKeys.set(
          item,
          parseableMethodKeys.includes(propKey as keyof Service)
        );
      }
      return this._validatedKeys.get(item) ?? false;
    };
    this.observables = new Proxy(this, {
      //_J  target:
      //_J propKey: the name of the function called through the proxy
      get(
        target,
        propKey: string
      ): (...args: unknown[]) => Observable<unknown> | never {
        //_J Reflect.get() Returns the value of the property (like getting a property from an object)
        //_J In this case, it returns
        const value = Reflect.get(target, propKey) as (...arg: unknown[])=> Promise<unknown>;
        if (_isValidMethod(propKey, value)) {
          return function (...args: unknown[]) {
            return defer(() => value.bind(target)(...args));
          };
        }
        throw new Error('Method not found or not convertible to promise ');
      },
    }) as unknown as ServiceObservableWrapper<Service>;
  }
}
