import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as customRouterEffects from './custom-router.effects';
import { customRouterFeature } from './custom-router.feature';

@NgModule({
  imports: [
    StoreModule.forFeature(customRouterFeature),
    EffectsModule.forFeature(customRouterEffects),
  ],
})
export class CustomRouterModule {}
